import { styled } from '@mui/material/styles'

import Layout from 'components/commons/Layout'
import Typography from 'components/commons/Typography'
import Button from 'components/commons/Button'
import StateImage from 'components/commons/StateImage'

import redirect from 'helpers/redirect'
import { useTranslation } from 'react-i18next'

const StyledContainer = styled('div')(({ theme }) => ({
  padding: '40% 16% 0 16%',
  textAlign: 'center',
  color: 'white',
  svg: {
    color: theme.palette.info.main,
    fontSize: 50,
  },
}))

const StyledBackToHomeBtn = styled(Button)({
  border: '1px solid #CB1C4F',
  borderRadius: '18px',
  color: 'white',
  fontWeight: 'bold',
  fontSize: 14,
  marginTop: 12,
  width: 159,
})

function PageNotFound(): JSX.Element {
  const { t } = useTranslation()
  return (
    <Layout>
      <StyledContainer>
        <StateImage type="empty-data" />
        <Typography fontSize="20px" fontWeight="700">
          {t('eventNotFoundTitle')}
        </Typography>
        <Typography
          color="#secondary"
          fontSize="14px"
          fontWeight="400"
        >
          {t('eventNotFoundSubtitle')}
        </Typography>
        <StyledBackToHomeBtn onClick={() => redirect('/')}>
          {t('backToTiptip')}
        </StyledBackToHomeBtn>
      </StyledContainer>
    </Layout>
  )
}

export default PageNotFound
